const datetime = require("./datetimeService")
const moment = require("moment")

export const formatDiferencaDatas = (dataIni, dataFim) => {
    let diferenca = datetime.calcularDiferencaDatas(dataIni, dataFim, "seconds")

    let passado = true
    let retorno = "Há "

    if (diferenca < 0) {
        passado = false
        diferenca *= -1
        retorno = "Daqui "
    }

    if (diferenca == 0)
        return "Agora"
    if (diferenca < 60) // 1 minuto
        return `${retorno} alguns segundos`
    if (diferenca < 120) // 2 minutos
        return `${retorno} 1 minuto`
    if (diferenca < 3600) // 1 hora
        return `${retorno} ${(diferenca / 60).toFixed(0)} minutos`
    if (diferenca < 7200) // 2 horas
        return `${retorno} 1 hora`
    if (diferenca < 86400) // 2 horas
        return `${retorno} ${(diferenca / 3600).toFixed(0)} horas`
    if (diferenca < 172800) // 2 dias
        return `${retorno} 1 dia`
    if (diferenca < 2592000) // 2 dias
        return `${retorno} ${(diferenca / 86400).toFixed(0)} dias`
    return `${passado ? "Há" : "Daqui"} algum tempo`
}

export const formatDataHora = (datahora, format) => {
    return moment(datahora).format(format)
}

export const formatCPFCNPJ = (cpf_cnpj) => {
    const formatCPF = (cpf) => {
        // 123.456.789-10 - 14 caracteres completos
        // 12345678910 - 11 caracteres resumido
        if (cpf.length == 14) return cpf
        if (cpf.length != 11) return cpf

        let p1 = cpf.substring(0, 3)
        let p2 = cpf.substring(3, 6)
        let p3 = cpf.substring(6, 9)
        let p4 = cpf.substring(9, 11)

        return `${p1}.${p2}.${p3}-${p4}`
    }

    const formatCNPJ = (cpf) => {
        // 48.287.560/0001-03 - 18 caracteres completos
        // 48287560000103 - 14 caracteres resumido
        if (cpf.length == 14) return cpf
        if (cpf.length != 11) return cpf

        let p1 = cpf.substring(0, 2)
        let p2 = cpf.substring(2, 5)
        let p3 = cpf.substring(5, 8)
        let p4 = cpf.substring(8, 12)
        let p5 = cpf.substring(12, 14)

        return `${p1}.${p2}.${p3}/${p4}-${p5}`
    }

    return cpf_cnpj.length == 11 ? formatCPF(cpf_cnpj) : formatCNPJ(cpf_cnpj)
}

export const formatReal = (valor) => {
    if (valor == 0) return "R$ 0,00"
    else if (!valor) return
    valor = valor + 0.0
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}


export const formatMesReferencia = (mes, ano) => {
    let meses = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"]

    return `${meses[mes - 1]}/${ano}`
}

export const formatEndereco = ({ logradouro, numero, bairro, complemento, cidade }) => {
    return `${logradouro}${numero ? `, ${numero}` : ""}${complemento ? ` - ${complemento}` : ""}${bairro ? `, ${bairro}` : ""} - ${cidade?.nome} / ${cidade?.estado_sigla}`
}