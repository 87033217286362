import React, { useEffect, useState, useRef } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

//i18n
import DefaultForm from "../../form/DefaultForm"

import SweetAlert from "react-bootstrap-sweetalert"

import { postAdministrador, putAdministrador } from "../../../services/administradores"
import Carregando from "../../Carregando"

export default function ModalAdministradores({ opened, setOpened, refresh, values, setAdministradores, ...props }) {
    const formRef = useRef()

    const [form, setForm] = useState({})

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    useEffect(() => {
        setForm(() => {
            let form = {}

            form.nome = { name: "nome", type: "text", label: "Nome", placeholder: "Nome", errorMessage: "Campo obrigatório", validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } }
            form.email = { name: "email", type: "text", label: "E-mail", placeholder: "E-mail", errorMessage: "Campo obrigatório", validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } }
            if (!values)
                form.senha = { name: "senha", type: "password", label: "Senha", placeholder: "Senha", errorMessage: "Campo obrigatório", validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } }
            form.nivel = { name: "nivel", type: "number", label: "Nível", placeholder: "Nível", errorMessage: "Campo obrigatório", validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } }

            return form
        })
    }, [opened])

    useEffect(async () => {
        if (values) {
            let newJson = { ...form }
            Object.keys(newJson).forEach(key => {
                if (newJson[key].type != "multiselect")
                    newJson[key] = { ...newJson[key], value: values?.hasOwnProperty(key) ? values[key] : null }
            })
            //console.log("NEW JSON", newJson)

            delete newJson.senha

            setForm(() => newJson)
        }
    }, [opened, values])

    const handleSubmit = async (event, errors, val) => {
        try {
            setLoadingSubmit(() => true)

            console.log("{ handleSubmit }", errors, val)
            if (errors.length > 0) return

            let data = val

            let result;
            if (values) {
                result = await putAdministrador(values._id, data)

                if (refresh) refresh(result, "edit") // Passar usuario para componente pai
            } else {
                result = await postAdministrador(data)

                if (refresh) refresh(result) // Passar usuario para componente pai
            }

            setSweetAlert({ ...sweetAlert, show: true, title: "Sucesso!", text: `Administrador ${values ? "atualizada" : "cadastrada"} com sucesso`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalEdicaoAdministradores ERROR", error)

            setSweetAlert({ ...sweetAlert, show: true, title: "Ooops!", text: error.data || "Ocorreu um erro", success: false, error: true })
        } finally {
            setLoadingSubmit(() => false)
        }
    }

    return (
        <Modal size="md" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {`${values ? "Atualizar" : "Cadastrar"} Administrador`}
            </ModalHeader>
            <ModalBody>
                {loading ?
                    <Carregando /> :
                    <DefaultForm formRef={formRef} fields={form} handleSubmit={handleSubmit} buttonText="Confirmar" buttonSubmitDisabled={loadingSubmit} />
                }

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
}