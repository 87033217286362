import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { getUsers } from "../../../store/contacts/actions"
import AdministradoresListaColunas from "./AdministradoresListaColunas"
import { isEmpty } from "lodash"

import { deleteAdministrador, getAdministradores } from "../../../services/administradores"

import ModalAdministradores from "../../../components/modals/administradores/ModalAdministradores"

import SweetAlert from "react-bootstrap-sweetalert"

import Carregando from "../../../components/Carregando"

const AdministradoresLista = props => {
  function translate(text) {
    return props.t(text)
  }

  const { users, onGetUsers } = props

  const [list, setList] = useState([])
  const [listView, setListView] = useState([])
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 1000,
    totalSize: list.length,
    custom: true
  })
  const { SearchBar } = Search

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const [modalOpened, setModalOpened] = useState(false)
  const [modalEdicao, setModalEdicao] = useState(null) // Armazena os dados que serão utilizados na edição
  const [modalEdicaoSenha, setModalEdicaoSenha] = useState(null)
  const [modalEdicaoOpened, setModalEdicaoOpened] = useState(false)
  const [modalEdicaoSenhaOpened, setModalEdicaoSenhaOpened] = useState(false)

  const [loading, setLoading] = useState(true)

  const [categoria, setCategoria] = useState(null)

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  useEffect(() => {
    buscarAdministradores()
  }, [])

  useEffect(() => {
    if (!modalEdicaoOpened)
      setModalEdicao(null)
  }, [modalEdicaoOpened])

  useEffect(() => {
    if (!modalEdicaoSenhaOpened)
      setModalEdicaoSenha(null)
  }, [modalEdicaoSenhaOpened])

  useEffect(() => {
    setModalEdicaoOpened(modalEdicao != null)
  }, [modalEdicao])

  useEffect(() => {
    setModalEdicaoSenhaOpened(modalEdicaoSenha != null)
  }, [modalEdicaoSenha])

  useEffect(() => {
    if (!isEmpty(users)) {
      setList(users)
    }
  }, [users])

  const buscarAdministradores = async () => {
    try {
      setLoading(() => true)

      let tiposCerveja = await getAdministradores()
      setList(tiposCerveja)
      setListView(tiposCerveja)
    } catch (error) {

    } finally {
      setLoading(() => false)
    }
  }
  const remover = async (item) => {
    try {
      console.log("remover", item)
      setSweetAlert({
        ...sweetAlert,
        show: true,
        title: "Atenção!",
        text: `Tem certeza que deseja excluir o administrador ${item.nome}?`,
        showCancel: true,
        cancelBtnBsText: "Não",
        cancelBtnBsStyle: "danger",
        success: false,
        error: false,
        warning: true,
        onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
        onConfirm: async () => {
          setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: translate("Aguarde"), text: "Estamos removendo" })
          try {
            let retorno = await deleteAdministrador(item._id)
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: "Sucesso", text: retorno || "Removido com sucesso!", onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
            handleRefresh(item, "remove")
          } catch (error) {
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: "Ooops", text: error.message || "Ocorreu um erro.", onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleRefresh = async (item, action = "add") => {
    console.log("{ handleRefresh }", action, list, item)
    if (action == "add") {
      let newArray = [...list, item]
      setList(newArray)
      setPageOptions({ ...pageOptions, totalSize: list.length + 1 })
      handleTableChange(null, { page, searchText }, newArray)
    } else if (action == "edit") {
      let newArray = [...list]
      for (let i = 0; i < newArray.length; i++) {
        const el = newArray[i]
        if (el._id == item._id) {
          newArray[i] = item
          break
        }
      }
      handleTableChange(null, { page, searchText }, newArray)
    } else if (action == "remove") {
      let newArray = list.filter(vari => vari._id != item._id)
      setList(newArray)
      setPageOptions({ ...pageOptions, totalSize: newArray.length - 1 })
      handleTableChange(null, { page, searchText }, newArray)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }, data = null) => {
    const dados = data || list
    console.log("{ handleTableChange }", page, searchText, dados)
    setPage(page)
    setSearchText(searchText)

    let inicio = pageOptions.sizePerPage * page - pageOptions.sizePerPage
    let fim = pageOptions.sizePerPage * page
    if (fim > dados.length) fim = dados.length

    setListView(
      dados.slice(inicio, fim).filter(user =>
        Object.keys(user).some(
          key =>
            typeof user[key] === "string" &&
            user[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const titles = {
    classe: "Classes",
    tarifa: "Tarifas"
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Administradores" breadcrumbItem={`Lista de Administradores`} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {loading ?
                    <Carregando /> :
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={listView || []}
                          columns={AdministradoresListaColunas(remover, setModalOpened)}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="row mb-2">
                                <Col md={6}>
                                  <div className="mb-3">
                                    <Link to="#" className="btn btn-success waves-effect waves-light" onClick={() => setModalOpened(true)}><i className="mdi mdi-plus me-2"></i>Novo</Link>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="form-inline float-md-end mb-3">
                                    <div className="search-box ms-2">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="mdi mdi-magnify search-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive mb-4">
                                    <BootstrapTable
                                      //selectRow={selectRowProp}
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={"table table-centered table-nowrap mb-0"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalAdministradores title="Estilo de Cerveja" opened={![null, false].includes(modalOpened)} values={modalOpened == true ? undefined : modalOpened} setOpened={setModalOpened} refresh={handleRefresh} />
      {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
    </React.Fragment>
  )
}

AdministradoresLista.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(AdministradoresLista)))


/*var selectRowProp = {
  mode: "checkbox",
  clickToSelect: false,

  title={titles[categoria]}
};*/