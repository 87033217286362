import axios from "axios";
import { logout } from "./authService"
require('dotenv').config()

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    params: {
        token: process.env.REACT_APP_INTEGRATION_TOKEN
    }
})

api.interceptors.request.use(config => {
    let access_token = localStorage.getItem("x-access-token")

    if (access_token)
        config.headers["x-access-token"] = access_token

    return config
})

api.interceptors.response.use(resp => {
    return resp
}, (error) => {
    if (error.response && error.response.status == 401 && error.response.data && error.response.data.logout)
        logout()
    throw error.response
})

export default api;
