import PropTypes from 'prop-types'
import React, { useEffect, useState, useContext } from "react"

import { Row, Col, Alert, Container, CardBody, Card } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../../store/actions"

// import images

import { login } from "../../../services/authService"

import AuthContext from '../../../contexts/AuthContext'
import Copyright from '../../../components/Copyright'

import styles from "./styles.scss"

const Login = (props) => {
  function translate(text) {
    return props.t(text)
  }

  const [loading, setLoading] = useState(false)
  const { setUsuario } = useContext(AuthContext)

  const [error, setError] = useState(null)

  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    try {
      setError(() => null)
      console.log(values)
      setLoading(true)
      const resultado = await login(values.email, values.senha)

      setUsuario(resultado)

      localStorage.setItem("x-access-token", resultado.token)

      //alert(resultado["x-access-token"])
      props.history.push("/dashboard")
    } catch (error) {
      setError(() => error.data || "Ocorreu um erro")
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log(props)
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={12} lg={12} xl={12} className="card-container">
              <Card className="card-login">

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5>Seja bem-vindo</h5>
                    <p className="text-muted">Entre com sua conta para continuar</p>
                  </div>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label={translate("E-mail")}
                          className="form-control"
                          placeholder={translate("Digite seu e-mail")}
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="senha"
                          label={translate("Senha")}
                          type="password"
                          required
                          placeholder={translate("Informe sua senha")}
                        />
                      </div>

                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="customControlInline" name="lembrar" />
                        <label className="form-check-label" htmlFor="customControlInline">
                          {translate("Lembrar de mim")}
                        </label>
                      </div>

                      {error &&
                        <div className="mt-3 text-center" style={{ color: "red" }}>
                          <p>{error}</p>
                        </div>
                      }

                      <div className="mt-3">
                        <button className="btn btn-dark w-100 waves-effect waves-light" type="submit" disabled={loading}>Entrar</button>
                      </div>
                    </AvForm>

                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <Copyright ano={true} orientacao="center" color="white" />
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(withTranslation()(Login))
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}
