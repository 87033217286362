import React from "react"
import Avatar from "../../../components/Avatar"
import IconButton from "../../../components/IconButton"

const ConteudoListaColunas = (setModalOpened) => [
  {
    dataField: "nome",
    text: "Nome",
    sort: true,
  },
  {
    dataField: "codigo",
    text: "Código",
    sort: true,
  },
  {
    text: "",
    formatter: (cellContent, item) => <>
      {item.tipo == "file" &&
        <a href={item.conteudo} target="_blank">Link</a>
      }
    </>
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    formatter: (cellContent, item) => (
      <ul className="list-inline font-size-20 contact-links mb-0" style={{ textAlign: "right" }}>
        <li className="list-inline-item dropdown">
          <IconButton className="btn-warning btn-sm" style={{ marginRight: 10 }} onClick={() => { setModalOpened(item) }}>
            <i className="uil uil-edit"></i>
          </IconButton>
        </li>
      </ul>
    ),
  },
]

export default ConteudoListaColunas