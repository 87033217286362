import React from "react"
import { Container, Row, Col } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import Copyright from "../Copyright"

const Footer = (props) => {
  function translate(text) {
    return props.t(text)
  }

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © CAPEBE.</Col>
            <Col sm={6}>
              <Copyright />
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default withTranslation()(Footer)
