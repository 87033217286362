import React, { useEffect, useState, useRef } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

//i18n
import DefaultForm from "../../form/DefaultForm"

import SweetAlert from "react-bootstrap-sweetalert"

import { postDenuncia, putDenuncia } from "../../../services/denuncias"
import Carregando from "../../Carregando"

export default function ModalResponderDenuncia({ opened, setOpened, refresh, values, setDenuncias, ...props }) {
    const formRef = useRef()

    const [form, setForm] = useState({
        resposta: { name: "resposta", type: "textarea", label: "Resposta", placeholder: "Resposta", errorMessage: "Campo obrigatório", validate: { /*required: { value: true }*/ }, size: { lg: 12, md: 12, sm: 12 } },
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    useEffect(async () => {
        if (values) {
            let newJson = { ...form }
            Object.keys(newJson).forEach(key => {
                if (newJson[key].type != "multiselect")
                    newJson[key] = { ...newJson[key], value: values?.hasOwnProperty(key) ? values[key] : null }
            })
            //console.log("NEW JSON", newJson)

            setForm(newJson)
        }
    }, [opened, values])

    const handleSubmit = async (event, errors, val) => {
        try {
            setLoadingSubmit(() => true)

            console.log("{ handleSubmit }", errors, val)
            if (errors.length > 0) return

            let data = val

            let result = await putDenuncia(values._id, data)

            if (refresh) refresh(result, "edit") // Passar usuario para componente pai

            setSweetAlert({ ...sweetAlert, show: true, title: "Sucesso!", text: `Resposta salva com sucesso`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalEdicaoDenuncias ERROR", error, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: "Ooops!", text: (error.response && error.response.data) ? error.response.data : "Ocorreu um erro", success: false, error: true })
        } finally {
            setLoadingSubmit(() => false)
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {`Responder denúncia #${values.protocolo}`}
            </ModalHeader>
            <ModalBody>
                {loading ?
                    <Carregando /> :
                    <DefaultForm formRef={formRef} fields={form} handleSubmit={handleSubmit} buttonText="Confirmar" buttonSubmitDisabled={loadingSubmit} />
                }

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
}