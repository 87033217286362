import React from "react"
import { Link } from "react-router-dom"
import Avatar from "../../../components/Avatar"
import Badge from "../../../components/Badge"
import IconButton from "../../../components/IconButton"
import { formatCPFCNPJ, formatDataHora, formatReal } from "../../../services/formatService"

import "./colunas.styles.scss"

const situacoes = {
  "1": { color: "success", text: "FINALIZADO" },
  "2": { color: "warning", text: "ENCAMINHADO PARA ANÁLISE" },
  "3": { color: "secondary", text: "ABERTO" },
}

// 0 = Removido
// 1 = Ativo
// 2 = Aguardando pagamento
// 3 = Aguardando aprovação
// 4 = Agurdando dados
// 5 = Inativo
// 6 = Reprovado

const DenunciaListaColunas = (remover, setModalOpened, updateStatus, setModalRespostaOpened) => [
  {
    dataField: "protocolo",
    text: "Protocolo",
    sort: true,
  },
  // {
  //   text: "Denunciante",
  //   sort: true,
  //   formatter: (cellContent, item) => item.anonimo ? <>Anônimo</> : (
  //     <div>
  //       <p><b>Nome:</b> {item?.denunciante?.nome}</p>
  //       <p><b>Cargo:</b> {item?.denunciante?.cargo || <i>Não informado</i>}</p>
  //       <p><b>E-mail:</b> {item?.denunciante?.email || <i>Não informado</i>}</p>
  //       <p><b>Telefone:</b> {item?.denunciante?.telefone || <i>Não informado</i>}</p>
  //       <p><b>Celular:</b> {item?.denunciante?.celular || <i>Não informado</i>}</p>
  //     </div>
  //   )
  // },
  {
    text: "Tempo",
    sort: true,
    formatter: (cellContent, item) => item.status > 1 && (item.tempo / 1440) >= 4 ? <span style={{ color: "#FF0000" }}>{item.dias_text}</span> : item.dias_text
  },
  {
    text: "Situação",
    sort: true,
    formatter: (cellContent, item) => <Badge color={situacoes[`${item.status}`].color}>{situacoes[`${item.status}`].text}</Badge>
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    formatter: (cellContent, item) => (
      <ul className="list-inline font-size-20 contact-links mb-0" style={{ textAlign: "right" }}>
        <li className="list-inline-item dropdown">
          {/* <IconButton className="btn-danger btn-sm" onClick={() => { remover(item) }}>
            <i className="uil uil-trash"></i>
          </IconButton> */}

          {[2].includes(item.status) &&
            <>
              <IconButton className="btn-info btn-sm" onClick={() => { setModalRespostaOpened(item) }} style={{ marginLeft: 5 }}>
                {item?.resposta?.length > 0 ? `Editar resposta` : `Responder`}
              </IconButton>
              <IconButton className="btn-success btn-sm" onClick={() => { updateStatus(item, 1) }} style={{ marginLeft: 5 }}>
                Finalizar
              </IconButton>
            </>
          }

          {[3].includes(item.status) &&
            <IconButton className="btn-warning btn-sm" onClick={() => { updateStatus(item, 2) }} style={{ marginLeft: 5 }}>
              Encaminhar para Análise
            </IconButton>
          }

          <IconButton className="btn-dark btn-sm" onClick={() => { setModalOpened(item) }} style={{ marginLeft: 5 }}>
            <i className="uil uil-eye"></i>
          </IconButton>
        </li>
      </ul>
    ),
  },
]

export default DenunciaListaColunas