import api from "./api"

const getDenuncias = async (status) => {
    try {
        const resultado = await api.get(`/denuncias/${status}`)
        console.log("{ getDenuncias }", resultado)
        const distribuidoras = resultado.data
        return distribuidoras
    } catch (error) {
        throw error
    }
}

const getDenunciaByProtocolo = async (protocolo) => {
    try {
        const resultado = await api.get(`/denuncias/protocolo/${protocolo}`)
        console.log("{ getDenunciaByProtocolo }", resultado)
        const distribuidoras = resultado.data
        return distribuidoras
    } catch (error) {
        throw error
    }
}

const putDenuncia = async (id, data) => {
    try {
        const resultado = await api.put(`/denuncias/${id}`, data)
        const distribuidoras = resultado.data
        console.log("putDenuncia result", distribuidoras)
        return distribuidoras
    } catch (error) {
        throw error
    }
}

const postDenuncia = async (data) => {
    try {
        const resultado = await api.post("/denuncias", data, { headers: { "Content-Type": `multipart/form-data; boundary=${data._boundary}` } })
        const distribuidoras = resultado.data
        console.log("postDenuncias result", distribuidoras)
        return distribuidoras
    } catch (error) {
        throw error
    }
}

const deleteDenuncia = async (id) => {
    try {
        const resultado = await api.delete(`/denuncias/${id}`)
        const distribuidoras = resultado.data
        return distribuidoras
    } catch (error) {
        throw error
    }
}

export { getDenuncias, getDenunciaByProtocolo, postDenuncia, putDenuncia, deleteDenuncia }