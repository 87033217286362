import React, { useEffect, useState, useRef } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

//i18n
import DefaultForm from "../../form/DefaultForm"

import SweetAlert from "react-bootstrap-sweetalert"

import { getDenunciaByProtocolo, postDenuncia, putDenuncia } from "../../../services/denuncias"
import Carregando from "../../Carregando"
import { getTiposOcorrencia } from "../../../services/tiposOcorrencias"
import { formatDataHora } from "../../../services/formatService"
import IconButton from "../../IconButton"

const situacoes = {
    "1": { color: "success", text: "FINALIZADO" },
    "2": { color: "warning", text: "ENCAMINHADO PARA ANÁLISE" },
    "3": { color: "secondary", text: "ABERTO" },
}

export default function ModalDenuncias({ opened, setOpened, refresh, values, setDenuncias, ...props }) {
    const formRef = useRef()

    const [form, setForm] = useState({
        tipo_ocorrencia: { name: "tipo_ocorrencia", type: "select", options: [], label: "Tipo de Ocorrência", placeholder: "Tipo de Ocorrência", errorMessage: "Campo obrigatório", validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
        descricao: { name: "descricao", type: "textarea", label: "Descrição", placeholder: "Descrição", errorMessage: "Campo obrigatório", validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
        anexos: { name: "anexos", type: "file", multiple: true, limitSize: 20 * 1024 * 1024, label: "Anexos", placeholder: "Anexos", errorMessage: "Campo obrigatório", validate: { required: { value: false } }, size: { lg: 12, md: 12, sm: 12 } },
        anonimo: { name: "anonimo", type: "select", options: [{ label: "Sim", value: 1 }, { label: "Não", value: 0 }], label: "Anônimo", placeholder: "Anônimo", errorMessage: "Campo obrigatório", validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 }, onChange: ({ target }) => anonimoAlterado(target.value) },
        protocolo_anterior: { name: "protocolo_anterior", type: "text", label: "Protocolo Anterior", placeholder: "Protocolo Anterior", errorMessage: "Campo obrigatório", validate: { required: { value: false } }, size: { lg: 12, md: 12, sm: 12 } },
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const [viewAnterior, setViewAnterior] = useState(false)
    const [loadingAnterior, setLoadingAnterior] = useState(false)
    const [denunciaAnterior, setDenunciaAnterior] = useState(null)

    useEffect(async () => {
        if (values) {
            let newJson = { ...form }
            Object.keys(newJson).forEach(key => {
                if (newJson[key].type != "multiselect")
                    newJson[key] = { ...newJson[key], value: values?.hasOwnProperty(key) ? values[key] : null }
            })
            //console.log("NEW JSON", newJson)

            setForm(newJson)
        }

        if (opened) {
            console.log(values)
            buscarTiposOcorrencia()
        } else {
            setViewAnterior(false)
            setDenunciaAnterior(null)
        }
    }, [opened, values])

    useEffect(() => {
        if (viewAnterior) {
            if (!denunciaAnterior)
                buscarDenunciaAnterior()
        }
    }, [viewAnterior])

    const buscarTiposOcorrencia = async () => {
        let resultado = await getTiposOcorrencia()
        setForm(form => ({ ...form, tipo_ocorrencia: { ...form.tipo_ocorrencia, options: resultado.map(tipo => ({ label: tipo.nome, value: tipo._id })) } }))
    }

    const handleSubmit = async (event, errors, val) => {
        try {
            setLoadingSubmit(() => true)

            console.log("{ handleSubmit }", errors, val)
            if (errors.length > 0) return

            let data = val

            let result;
            if (values) {
                // result = await putDenuncia(values._id, data)

                // if (refresh) refresh(result, "edit") // Passar usuario para componente pai
            } else {
                const formData = new FormData();

                Object.keys(data).filter(key => key != "anexos").map(key => {
                    formData.append(key, data[key]);
                })

                for (let anexo of data.anexos)
                    formData.append("files", anexo);

                console.log(formData)

                result = await postDenuncia(formData)

                if (refresh) refresh(result) // Passar usuario para componente pai
            }

            setSweetAlert({ ...sweetAlert, show: true, title: "Sucesso!", text: `Dúvida ${values ? "atualizada" : "cadastrada"} com sucesso`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalEdicaoDenuncias ERROR", error, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: "Ooops!", text: (error.response && error.response.data) ? error.response.data : "Ocorreu um erro", success: false, error: true })
        } finally {
            setLoadingSubmit(() => false)
        }
    }

    const anonimoAlterado = async (anonimo) => {
        try {
            console.log("anonimoAlterado", anonimo)

            setForm(form => {
                form = { ...form }
                if (anonimo == 1) {
                    let { divider, nome, cargo, email, telefone, celular, ...newForm } = form
                    form = newForm
                } else {
                    form = {
                        ...form,
                        divider: { type: "component", component: <b>Denunciante</b>, size: { lg: 12, md: 12, sm: 12 } },
                        nome: { name: "denunciante.nome", type: "text", label: "Nome", placeholder: "Nome", errorMessage: "Campo obrigatório", validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
                        cargo: { name: "denunciante.cargo", type: "text", label: "Cargo / Planta", placeholder: "Cargo / Planta", errorMessage: "Campo obrigatório", validate: { required: { value: false } }, size: { lg: 6, md: 6, sm: 12 } },
                        email: { name: "denunciante.email", type: "text", label: "E-mail", placeholder: "E-mail", errorMessage: "Campo obrigatório", validate: { required: { value: false } }, size: { lg: 4, md: 4, sm: 12 } },
                        telefone: { name: "denunciante.telefone", type: "text", label: "Telefone", placeholder: "Telefone", errorMessage: "Campo obrigatório", validate: { required: { value: false } }, size: { lg: 4, md: 4, sm: 12 } },
                        celular: { name: "denunciante.celular", type: "text", label: "Celular", placeholder: "Celular", errorMessage: "Campo obrigatório", validate: { required: { value: false } }, size: { lg: 4, md: 4, sm: 12 } },
                    }
                }
                console.log(form)
                return form
            })
        } catch (error) {
            console.error(error)
        }
    }

    const buscarDenunciaAnterior = async () => {
        try {
            setLoadingAnterior(() => true)

            let protocolo = values.protocolo_anterior

            let denuncia = await getDenunciaByProtocolo(protocolo)

            setDenunciaAnterior(denuncia)
        } catch (error) {
            alert("Ocorreu um erro")
        } finally {
            setLoadingAnterior(() => false)
        }
    }

    const Content = ({
        protocolo,
        identificacao,
        denunciante,
        area,
        comunicada,
        conhecimento,
        denunciando,
        denuncia,
        diretorCiente,
        diretorEnvolvido,
        diretorEsconder,
        evidencias,
        filial,
        funcionario,
        sugestao,
        testemunhas,
        tipoDenuncia,
        valorFinanceiro,
        historico,
        resposta,
        anexos
    }) => (
        <div style={{ fontSize: 16 }}>
            <div>
                <p style={{ margin: 0 }}><b>Protocolo: </b>{protocolo}</p>
            </div>
            <hr />
            <div>
                <b>Denunciante:</b>
                {identificacao ?
                    <ul>
                        <li><b>Nome: </b>{denunciante?.nome}</li>
                        <li><b>E-mail: </b>{denunciante?.email || <i>Não informado</i>}</li>
                        <li><b>Telefone: </b>{denunciante?.telefone || <i>Não informado</i>}</li>
                    </ul> :
                    " Anônimo"
                }
            </div>
            <hr />
            <div>
                <b>Você é funcionário da empresa?</b>
                <p>{funcionario ? "Sim" : "Não"}</p>
            </div>
            <hr />

            <div>
                <b>A denúncia já foi previamente comunicada à auditoria?</b>
                <p>{comunicada ? "Sim" : "Não"}</p>
            </div>
            <hr />

            <div>
                <b>Qual o tipo de denúncia melhor se enquadra ao fato que você está registrando?</b>
                <p>{tipoDenuncia}</p>
            </div>
            <hr />

            <div>
                <b>Indique a filial/unidade onde ocorreu o fato que você está denunciando:</b>
                <p>{filial}</p>
            </div>
            <hr />

            <div>
                <b>Indique a área/setor onde ocorreu o fato que você está denunciando:</b>
                <p>{area}</p>
            </div>
            <hr />

            <div>
                <b>Sobre o fato que você está denunciando, você tem:</b>
                <p>{denunciando}</p>
            </div>
            <hr />

            <div>
                <b>Você sabe se algum Diretor, Gerente, Coordenador, Supervisor, ou Chefia está CIENTE do problema relatado?</b>
                <p>{diretorCiente}</p>
            </div>
            <hr />

            <div>
                <b>Você sabe se algum Diretor, Gerente, Coordenador, Supervisor, ou Chefia está ENVOLVIDO diretamente no fato relatado?</b>
                <p>{diretorEnvolvido}</p>
            </div>
            <hr />

            <div>
                <b>Você sabe se algum(ns) Diretor(es), Gerente(s), Coordenador(es), Supervisor(es), ou Chefia(s) tentou(ram) ESCONDER o problema relatado?</b>
                <p>{diretorEsconder}</p>
            </div>
            <hr />

            <div>
                <b>O que você quer denunciar?</b>
                <p>{denuncia}</p>
            </div>
            <hr />

            <div>
                <b>Existem testemunhas? Em caso positivo, indique-as.</b>
                <p>{testemunhas}</p>
            </div>
            <hr />

            <div>
                <b>Você sabe se existem evidências sobre o fato? Em caso positivo, indique-as.</b>
                <p>{evidencias}</p>
            </div>
            <hr />

            <div>
                <b>Caso seja possível, indique o valor financeiro envolvido no fato relatado:</b>
                <p>{valorFinanceiro}</p>
            </div>
            <hr />

            <div>
                <b>Caso você tenha alguma sugestão de como solucionar o problema, descreva-a:</b>
                <p>{sugestao}</p>
            </div>
            <hr />

            <div>
                <b>Como você teve conhecimento?</b>
                <p>{conhecimento}</p>
            </div>
            <hr />

            <div>
                <b>Histórico:</b>
                <ul>
                    {(historico || []).map((etapa, i) => <li key={i}><b>{formatDataHora(etapa.datahora, "DD/MM/YYYY - HH:mm:ss")}</b> - {situacoes[`${etapa.status}`].text}</li>)}
                </ul>
            </div>
            <hr />
            <div>
                <b>Resposta:</b>
                <p>{resposta || <i>Sem resposta</i>}</p>
            </div>
            <div>
                <b>Anexos:</b>
                <div>
                    {anexos?.length > 0 ?
                        <ul>
                            {anexos.map(anexo => <li key={anexo.id}><a href={anexo.url} target="_blank">{anexo?.name || "Arquivo"}</a></li>)}
                        </ul> :
                        <i>Nenhum anexo</i>
                    }
                </div>
            </div>
        </div>
    )

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {values ? `Denúncia #${values.protocolo}` : "Cadastrar Denúncia"}
            </ModalHeader>
            <ModalBody>
                {loading ?
                    <Carregando /> :
                    values ?
                        <Content {...values} /> :
                        <DefaultForm formRef={formRef} fields={form} handleSubmit={handleSubmit} buttonText="Confirmar" buttonSubmitDisabled={loadingSubmit} />
                }

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
}