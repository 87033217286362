import React, { useState, useContext } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user4 from "../../../assets/images/users/avatar-4.jpg"

import AuthContext from "../../../contexts/AuthContext"

import { getUsuario } from "../../../services/authService"

import ModalMeusDados from "../../../components/modals/meus-dados/ModalMeusDados"

import AvatarDefault from "../../../assets/images/avatar-default.jpeg"

const ProfileMenu = props => {
  const { usuario } = useContext(AuthContext)

  const [modalMeusDadosOpened, setModalMeusDadosOpened] = useState(false)

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{usuario && usuario.nome}</span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={() => { setModalMeusDadosOpened(true) }}>
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {props.t("Meus dados")}{" "}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/login" className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Sair")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <ModalMeusDados opened={modalMeusDadosOpened} setOpened={setModalMeusDadosOpened} usuario={usuario} />
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
