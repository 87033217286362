import api from "./api"

const getTiposOcorrencia = async () => {
    try {
        const resultado = await api.get("/tipos-ocorrencia")
        console.log("{ getTiposOcorrencia }", resultado)
        return resultado.data
    } catch (error) {
        throw error
    }
}

export { getTiposOcorrencia }