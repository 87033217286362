import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Carregando from "../../components/Carregando"
import { getRelatorioDashboard } from "../../services/relatorios"

const Dashboard = () => {
  const [loading, setLoading] = useState(true)

  const [relatorio, setRelatorio] = useState({})

  useEffect(() => {
    buscarRelatorio()
  }, [])

  const buscarRelatorio = async () => {
    try {
      setLoading(() => true)

      let relatorio = await getRelatorioDashboard()
      setRelatorio(relatorio)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(() => false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            {[
              { _id: 3, text: "Abertos" },
              { _id: 2, text: "Encaminhados para análise" },
              { _id: 1, text: "Fechados" },
            ].map((etapa, i) => (
              <Col key={i} lg={4} md={4} sm={12}>
                <Card>
                  <CardBody style={{ textAlign: "center" }}>
                    {loading ? <Carregando /> : <>
                      <p style={{ margin: 0 }}>{etapa.text}</p>
                      <h2>{relatorio?.status?.filter(s => s._id == etapa._id)?.[0]?.quantidade || 0}</h2>
                    </>}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
