import React, { useEffect, useState, useContext } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import DefaultForm from "../../form/DefaultForm"

import SweetAlert from "react-bootstrap-sweetalert"

import AuthContext from "../../../contexts/AuthContext"

import { putUsuario } from "../../../services/usuarios"

export default withTranslation()(function ModalMeusDados({ opened, setOpened, ...props }) {
    function translate (text) {
      return props.t(text)
    }
    
    const { usuario, setUsuario } = useContext(AuthContext)

    const [form, setForm] = useState({
        nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: "Campo obrigatório", validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
        email: { name: "email", type: "email", label: translate("E-mail"), placeholder: translate("E-mail"), errorMessage: "Campo obrigatório", validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 }, disabled: true }
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        if (usuario)
            start(usuario)
    }, [usuario])

    const start = (dados) => {
        let newJson = { ...form }

        newJson.nome.value = dados.nome
        newJson.email.value = dados.email

        console.log("newJson", newJson)

        setForm(newJson)
    }

    const handleSubmit = async (event, errors, values) => {
        try {
            console.log("{ handleSubmit }", errors, values, form)
            if (errors.length > 0) return

            const { email, ...dados } = values

            const resultado = await putUsuario("sessao", dados)

            console.log("USUARIO CADASTRADO", resultado)

            setUsuario(resultado)
            start(resultado)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Usuário")} ${usuario.nome} ${translate("cadastrado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalCadastroUsuario ERROR", error, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"), text: (error.response && error.response.data) ? error.response.data : translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Meus Dados")}
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Atualizar")} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})