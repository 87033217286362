import React from 'react'

import "./styles.scss"

export default function Badge({
    color,
    children
}) {
    return (
        <div className="container-custom-badge">
            <div className={`custom-badge badge-${color}`}>{children}</div>
        </div>
    )
}