import api from "./api"

const getAdministradores = async () => {
    try {
        const resultado = await api.get("/usuarios")
        console.log("{ getAdministradores }", resultado)
        const distribuidoras = resultado.data
        return distribuidoras
    } catch (error) {
        throw error
    }
}

const putAdministrador = async (id, data) => {
    try {
        const resultado = await api.put(`/usuarios/${id}`, data)
        const distribuidoras = resultado.data
        console.log("putAdministrador result", distribuidoras)
        return distribuidoras
    } catch (error) {
        throw error
    }
}

const postAdministrador = async (data) => {
    try {
        const resultado = await api.post("/usuarios", data)
        const distribuidoras = resultado.data
        console.log("postAdministradores result", distribuidoras)
        return distribuidoras
    } catch (error) {
        throw error
    }
}

const deleteAdministrador = async (id) => {
    try {
        const resultado = await api.delete(`/usuarios/${id}`)
        const distribuidoras = resultado.data
        return distribuidoras
    } catch (error) {
        throw error
    }
}

export { getAdministradores, postAdministrador, putAdministrador, deleteAdministrador }