import React from "react"
import { Redirect } from "react-router-dom"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// Profile
import UserProfile from "../pages/Authentication/user-profile"
import AdministradoresLista from "../pages/Administradores/AdministradoresLista/AdministradoresLista"
import DenunciasLista from "../pages/Denuncias/DenunciasLista/DenunciasLista"
import ConteudosLista from "../pages/Conteudos/ConteudosLista/ConteudosLista"


const userRoutes = [
  { path: "/denuncias/:status", component: DenunciasLista },

  // Configurações
  { path: "/administradores", component: AdministradoresLista },

  { path: "/conteudos", component: ConteudosLista },

  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

const relatoriosRoutes = []

export { userRoutes, authRoutes, relatoriosRoutes }
