import api from "./api"

const getConteudos = async () => {
    try {
        const resultado = await api.get("/conteudos")
        const conteudos = resultado.data
        return conteudos
    } catch (error) {
        throw error
    }
}

const putConteudo = async (id, data) => {
    try {
        const resultado = await api.put(`/conteudos/${id}`, data)
        const conteudos = resultado.data
        return conteudos
    } catch (error) {
        throw error
    }
}

export { getConteudos, putConteudo }