import api from "./api"

export const getRelatorioDashboard = async () => {
    try {
        const resultado = await api.get("/relatorios/dashboard")

        const relatorio = resultado.data
        return relatorio
    } catch (error) {
        throw error
    }
}