// import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
// import germany from "../assets/images/flags/germany.jpg"
// import italy from "../assets/images/flags/italy.jpg"
// import russia from "../assets/images/flags/russia.jpg"
import ptBrFlag from "../assets/images/flags/brasil.jpg"

const languages = {
  ptBr: {
    label: "Português",
    flag: ptBrFlag,
  }
}

export default languages
