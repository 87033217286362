import React, { useEffect } from 'react'
import PropTypes from 'prop-types';

import { AvForm } from "availity-reactstrap-validation"

import { Row, Col, Button } from "reactstrap"

import "./style.scss"
import FormPart from './FormPart'

export default function DefaultForm({
    formRef = null,
    fields = {},
    handleSubmit = () => console.info("Form without handleSubmit"),
    buttonSubmit = true,
    buttonText = "Confirmar",
    buttonSubmitDisabled = false,
    buttons,
    logs = false,
    formChildren = false
}) {
    if (formChildren)
        return (
            <Row>
                {Object.keys(fields).map((field, index) => <FormPart key={index} input={fields[field]} />)}
            </Row>
        )

    return (
        <AvForm onSubmit={handleSubmit} ref={formRef}>
            <Row>
                {Object.keys(fields).map((field, index) => <FormPart key={index} input={fields[field]} />)}
            </Row>

            {(!formChildren && (buttons && buttons.length > 0) || buttonSubmit) && <Row>
                <Col lg={12} align="right" className="buttons">
                    {buttons && buttons.filter(b => typeof b == "object" && Object.keys(b).length > 0 && (!b.hasOwnProperty("show") || b.show)).map((button, index) => (
                        <Button key={index} {...button}>{button.text}</Button>
                    ))}

                    {buttonSubmit && <Button color="success" type="submit" disabled={buttonSubmitDisabled}>{buttonText}</Button>}
                </Col>
            </Row>}
        </AvForm>
    )
}

DefaultForm.propTypes = {
    fields: PropTypes.array,
    buttonSubmit: PropTypes.bool,
    buttonText: PropTypes.string,
    buttonSubmitDisabled: PropTypes.bool,
    buttons: PropTypes.array,
    logs: PropTypes.bool
};
