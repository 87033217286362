import api from './api'

const getUsuario = async () => {
    try {
        const resultado = await api.get("/sessao")

        console.log("{ getUsuario }", resultado)

        if (!resultado || !resultado.data) throw { status: 404 }

        return resultado.data
    } catch (error) {
        throw error
    }
}

const login = async (email, senha) => {
    try {
        console.log("login", email, senha)

        let dados = { email, senha }

        const resultado = await api.post("/sessao/login", dados)

        console.log("RESULTADO LOGIN", resultado)

        localStorage.setItem("x-access-token", resultado.data.token)

        return resultado.data
    } catch (error) {
        console.error(error, error.response)
        throw error
    }
}

const logout = async () => {
    try {
        localStorage.removeItem("x-access-token")

        //document.location.reload(true)

        return true
    } catch (error) {
        console.error(error)
        throw error
    }
}

const confirmar = async (uuid_confirmacao, senha) => {
    try {
        console.log("confirmar", uuid_confirmacao, senha)

        let dados = { uuid_confirmacao, senha }

        const resultado = await api.post("/usuarios/confirmar", dados)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}

export { getUsuario, login, logout, confirmar };