import React, { useState, useEffect, memo, useRef } from 'react'

import { AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Select from "react-select"
import Avatar from 'react-avatar-edit'

import { CopyToClipboard } from 'react-copy-to-clipboard';

import "./style.scss"
import TooltipHelp from '../../helpers/TooltipHelp'

// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'

import FileBase64 from 'react-file-base64';


const Input = (props) => {
    //console.log("Input", props)

    const { type = "text", id, name, label, help, options, errorMessage = "Campo incorreto", size, isMulti, isDisabled, ...rest } = props

    const ref = useRef(null)

    const [multiselectValue, setMultiselectValue] = useState([])
    const [src, setSrc] = useState(null)
    const [edit, setEdit] = useState(true)
    const [val, setVal] = useState("")
    const [editorState] = useState((type == "textarea-whatsapp" && props.value) ? EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML(props.value)
        )) : null)

    useEffect(() => {
        if (type === "multiselect" && props.selecteds) {
            handleChangeMultiselect(props.selecteds)
        }

        if (["upload-avatar", "upload-image"].includes(type)) {
            if (props.value) setSrc(props.value)
            if (props.hasOwnProperty("edit") && props.edit == false) setEdit(false)
        }

        if (type === "textarea-whatsapp") {
            if (props.value) {
                setVal(props.value)
            }
        }

        if (type === "file") {
            console.log("IMAGEMMMM", props)
            if (props.value) {
                setSrc(props.value)
            }
        }
    }, [props])

    useEffect(() => {
        console.log("mUDOU", props.value)
        console.log(ref)
    }, [props.value])

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 41943040) {
            alert("Arquivo muito grande!");
            elem.target.value = "";
        };
    }

    const handleChangeMultiselect = (values) => {
        console.log("{ handleChangeMultiselect }", values)
        let value = []
        if (values)
            values.forEach(valor => { value.push(valor.value) })
        setMultiselectValue(value)
    }



    try {
        return (
            <div className="mb-3">
                <div className="label-wrapper">
                    {label && label !== "" && <label htmlFor={id}>{label}{rest.validate && rest.validate.required && rest.validate.required.value && <span>*</span>}</label>}
                    {help && <TooltipHelp text={help} />}
                </div>

                {["text", "email", "textarea", "number", "color", "password", "time", "date"].includes(type) && <AvField className="mb-3" name={name} id={id || name} type={type} errorMessage={errorMessage} ref={ref} {...rest} />}
                {["hidden"].includes(type) && <AvField className="mb-3" name={name} id={id || name} type={"text"} errorMessage={errorMessage} {...rest} style={{ display: "none" }} />}
                {type === "file" && <>
                    <FileBase64
                        image={true}
                        multiple={props.multiple || false}
                        onDone={(files) => {
                            console.log(files)
                            if (rest.image && files.type.indexOf("image/") != 0) {
                                alert("O arquivo não é uma imagem")
                                return
                            }

                            let totalSize = 0;
                            (Array.isArray(files) ? files : [files]).map(file => totalSize += file.file.size)

                            console.log(totalSize, props.limitSize)

                            if (props.limitSize && totalSize > props.limitSize)
                                alert(`O upload realizado excede o limite permitido`)
                            else {
                                let result = props.multiple ? files.map(file => props.base64 ? file.base64 : file.file) : props.base64 ? files.base64 : files.file
                                setSrc(result)
                                if (props.onChange)
                                    props.onChange(result)
                            }
                        }}
                        {...rest}
                    />
                    <AvField className="mb-3" name={name} id={id || name} type={"textarea"} errorMessage={errorMessage} {...rest} style={{ display: "none" }} value={src} />
                </>}
                {type === "select" && <>
                    {/*<Select name={name} id={id || name} classNamePrefix="select2-search-disable" {...rest} />*/}
                    <AvField type="select" id={id || name} name={name} {...rest}>
                        {options && options.map((option, index) => (
                            <option key={index} value={option.value} >{option.label}</option>
                        ))}
                    </AvField>
                </>}
                {type === "multiselect" && <>
                    <Select {...rest} id={id || name} classNamePrefix="select2-search-disable" options={options} onChange={handleChangeMultiselect} defaultValue={props.selecteds || []} isMulti={isMulti} isDisabled={isDisabled} />
                    <AvField type="select" multiple name={name} {...rest} value={multiselectValue} style={{ display: "none" }}>
                        {options && options.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </AvField>
                </>}
                {type === "checkbox-group" && <>
                    <AvCheckboxGroup key={id || name} className="AvCheckboxGroup" inline name={name} id={id || name} {...rest} defaultValue={(() => {
                        let defaults = []
                        options.forEach(opt => { if (opt.checked) defaults.push(opt.value) })
                        return defaults
                    })()}>
                        {options && options.map((option, index) => (
                            <>
                                <AvCheckbox key={`${id || name}-${index}`} label={option.help ? <>{option.label} <TooltipHelp text={option.help} /></> : option.label} value={option.value} />
                                {rest.direction && rest.direction === "column" && <br />}
                            </>
                        ))}
                    </AvCheckboxGroup>
                </>}

                {["upload-avatar", "upload-image"].includes(type) && <>
                    {!edit ? <div style={{ position: "relative" }}>
                        <img src={src} style={{ borderRadius: '100%' }} />
                        <a href="#" style={{ position: "absolute", top: 0, left: 0, fontSize: 16, color: "#485056" }} onClick={() => setEdit(true)}>
                            <i className="uil-edit me-1"></i>
                        </a>
                    </div> : <>
                        <Avatar width={rest.width || 150} height={rest.height || 150} imageWidth={rest.width || 150} cropRadius={rest.cropRadius || 75} label="Escolha uma imagem" labelStyle={{ fontSize: 14 }} onCrop={pre => setSrc(pre)} onClose={() => {
                            if (props.hasOwnProperty("edit") && props.edit == false) { setEdit(false) }
                            if (props.value) { setSrc(props.value) }
                        }} onBeforeFileLoad={onBeforeFileLoad} exportQuality={1} {...rest} />
                        <AvField className="mb-3" name={name} id={id || name} type={type} errorMessage={errorMessage} {...rest} style={{ display: "none" }} value={src} />
                    </>}
                </>}

                {type === "info" && <p>{props.value}</p>}

                {type === "clipboard" && <>
                    <CopyToClipboard text={props.value} className="copy">
                        <div>
                            <p>{props.value}</p>
                            <i className="uil-copy" />
                        </div>
                    </CopyToClipboard>
                </>}

                {type === "editor" && <>
                    <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onChange={(e) => {
                            setVal(draftToHtml(e))
                        }}
                        defaultEditorState={editorState}
                    />
                    <AvField className="mb-3" name={name} type="textarea" {...rest} value={val} style={{ display: "none" }} />
                </>}

                {type === "row-group" && <>

                </>}
            </div >
        )
    } catch (error) {
        return <h5>Teste</h5>
    }
}

export default memo(Input)
