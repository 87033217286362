import React from "react"

import logoShark from "../../assets/images/shark-logo-mini.png"

import "./styles.scss"

export default function Copyright({
    ano = false,
    orientacao = "end",
    color = "black"
}) {
    return (
        <div className={`copyright-container d-none d-sm-block text-sm-${orientacao} color-${color}`}>
            {ano && <>
                &copy; {new Date().getFullYear()}.{` `}
            </>}Desenvolvido por
            <a href="https://sharkdev.com.br" target="_blank">
                <img src={logoShark} />
            </a>
        </div>
    )
}