import React, { useState, createContext, useEffect } from 'react'
import { getUsuario, logout } from '../services/authService'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {

    const [usuario, setUsuario] = useState(null)

    useEffect(() => {
        buscarUsuario()
    }, [])

    const buscarUsuario = async () => {
        try {
            let token = localStorage.getItem('x-access-token')

            if (token) {
                const user = await getUsuario()
                setUsuario(user)
            }
        } catch (error) {
            console.error(error)
            if (error?.status == 401) {
                logout()
                window.location.reload()
            }
        }
    }

    return (
        <AuthContext.Provider value={{
            usuario, setUsuario
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
